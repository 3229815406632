import { DashboardGaugeIcon } from '@panwds/icons';

export default ({ vars }) => {
  // eslint-disable-next-line no-undef
  const envName = (vars ?? {}).env_name || process.env.ENV_NAME;
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Env: ${envName}`);
  // eslint-disable-next-line no-undef
  console.info(`ADEM UI Version: ${process.env.APP_VERSION}`);

  if (envName === 'staging') {
    // eslint-disable-next-line no-undef
    console.info(
      '%c Release Changelog: ',
      'background: #222; color: #bada55',
      `https://prisma-access.docs.pan.run/sase-adem-group/dem-ui/changelog.txt`
    );
  }

  return import('./routes').then((routes) => ({
    key: 'dem',
    title: 'Autonomous DEM',
    icon: DashboardGaugeIcon,
    contentClassName: 'dem-root',
    path: '/dem',
    exact: false,
    noRBACheck: true,
    expandable: true,
    children: routes.default,
    component: () => import('./DemViewer'),
    avail: () => true,
    featureAvail: 'dem',
  }));
};
